//* ==========================================================================
//* ===== General  ===== */
//*========================================================================== */

.copy {
    background-color: rgb(84, 110, 122);
    color: white;



}

.copyright{
    background-color: rgb(84, 110, 122);
    color: white;
    padding: 1em;

}


span.spamprotection {display:none;}